@use './colors';
@use './fonts';
@import './normalize.scss';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --font-system: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

body {
  font-family: var(--font-system);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  overflow: hidden visible;
}

a {
  color: colors.$blue-500;
}

.ruby {
  display: inline;
  position: relative;
  cursor: pointer;
  border-bottom: 1px dotted colors.$tw-blue-500;
}

.ruby .rt {
  background: colors.$tw-blue-500;
  border-radius: 5px;
  border: 1px solid colors.$tw-blue-500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  color: colors.$white;
  display: block;
  font-size: 13px;
  font-weight: normal;
  left: 50%;
  opacity: 0;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  text-shadow: none;
  top: 0%;
  transform: translate(-50%, 0);
  transition: 300ms all ease-out;
  white-space: nowrap;
  z-index: 1;
}

.ruby:hover {
  color: colors.$tw-blue-500;
  border-bottom: 0;
}

.ruby:hover .rt {
  transform: translate(-50%, -110%);
  opacity: 1;
}
