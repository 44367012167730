:root {
  --white: #fff;
  --black: #000;
  --white-rgb: 255, 255, 255;

  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-50-rgb: 248, 250, 252;
  --slate-100-rgb: 241, 245, 249;
  --slate-200-rgb: 226, 232, 240;
  --slate-300-rgb: 203, 213, 225;
  --slate-400-rgb: 148, 163, 184;
  --slate-500-rgb: 100, 116, 139;
  --slate-600-rgb: 71, 85, 105;
  --slate-700-rgb: 51, 65, 85;
  --slate-800-rgb: 30, 41, 59;
  --slate-900-rgb: 15, 23, 42;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;

  --amber-50: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;

  --yellow-50: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;

  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;

  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;

  --purple-50: #faf5ff;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;

  --pink-50: #fdf2f8;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #be185d;
  --pink-800: #9d174d;
  --pink-900: #831843;

  --font-brand: 'Poppins', sans-serif;
  --font-reading: 'Noto Serif JP', sans-serif;
  --font-system: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;

  /* 9px */
  --font-size-3xs: 0.5625rem;
  /* 10px */
  --font-size-2xs: 0.625rem;
  /* 12px */
  --font-size-xs: 0.75rem;
  /* 14px */
  --font-size-sm: 0.875rem;
  /* 16px */
  --font-size-base: 1rem;
  /* 18px */
  --font-size-lg: 1.125rem;
  /* 20px */
  --font-size-xl: 1.25rem;
  /* 24px */
  --font-size-2xl: 1.5rem;
  /* 30px */
  --font-size-3xl: 1.875rem;
  /* 40px */
  --font-size-4xl: 2.5rem;

  --line-height-none: 1;
  --line-height-tight: 1.25;
  --line-height-snug: 1.375;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.625;
  --line-height-loose: 2;

  --space-negative-4: -16px;
  --space-negative-3: -12px;
  --space-negative-2: -8px;
  --space-negative-1: -4px;
  --space-0: 0;
  --space-025: 1px;
  --space-050: 2px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 20px;
  --space-6: 24px;
  --space-7: 28px;
  --space-8: 32px;
  --space-9: 36px;
  --space-10: 40px;
  --space-11: 44px;
  --space-12: 48px;
  --space-14: 56px;
  --space-15: 60px;
  --space-16: 64px;
  --space-20: 80px;
  --space-24: 96px;
  --space-28: 112px;
  --space-32: 128px;
  --space-36: 144px;
  --space-40: 160px;
  --space-44: 176px;
  --space-48: 192px;
  --space-52: 208px;
  --space-56: 224px;
  --space-60: 240px;
  --space-64: 256px;
  --space-72: 288px;
  --space-80: 320px;
  --space-96: 384px;

  --max-w-xs: 320px;
  --max-w-sm: 384px;
  --max-w-md: 448px;
  --max-w-lg: 512px;
  --max-w-xl: 576px;
  --max-w-2xl: 672px;
  --max-w-3xl: 768px;
  --max-w-4xl: 896px;
  --max-w-5xl: 1024px;
  --max-w-6xl: 1152px;
  --max-w-7xl: 1280px;

  --basis-0: 0px;
  --basis-1: 4px;
  --basis-2: 8px;
  --basis-3: 12px;
  --basis-4: 16px;
  --basis-5: 20px;
  --basis-6: 24px;
  --basis-7: 28px;
  --basis-8: 32px;
  --basis-9: 36px;
  --basis-10: 40px;
  --basis-11: 44px;
  --basis-12: 48px;
  --basis-14: 56px;
  --basis-16: 64px;
  --basis-20: 80px;
  --basis-24: 96px;
  --basis-28: 112px;
  --basis-32: 128px;
  --basis-36: 144px;
  --basis-40: 160px;
  --basis-44: 176px;
  --basis-48: 192px;
  --basis-52: 208px;
  --basis-56: 224px;
  --basis-60: 240px;
  --basis-64: 256px;
  --basis-72: 288px;
  --basis-80: 320px;
  --basis-96: 384px;

  --border-width-0: 0px;
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-3: 4px;
  --border-width-4: 8px;

  --border-radius-1: 2px;
  --border-radius-2: 4px;
  --border-radius-3: 6px;
  --border-radius-4: 8px;
  --border-radius-5: 12px;
  --border-radius-6: 16px;
  --border-radius-7: 24px;

  --shadow-1: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-2: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-3: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-4: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-5: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --shadow-6: 0 25px 50px -12px rgb(0 0 0 / 0.25);

  --z-1: 10;
  --z-2: 20;
  --z-3: 30;
  --z-4: 40;
  --z-5: 100;
  --z-debug: 9999;

  --container-width-xx-small: 320px;
  --container-width-xs: 450px;
  --container-width-sm: 580px;
  --container-width-md: 720px;
  --container-width-lg: 960px;
  --container-width-xl: 1260px;

  /* Manual sync with custom-media.module.css */
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1280px;
  --breakpoint-2xl: 1536px;
}